import { requestService } from '../utils/Utils.jsx';


export function getFeedback() {
    return requestService.get('/feedback');
}


export async function getUserFeedbackEligibility(userId, feature) {
    return requestService.get(`/feedback/${userId}/${feature}`).then(response => {
        if (response.data.message !== "submitted"){
            console.log("getUserFeedbackEligibility true")
            return true
        } else{
            console.log("getUserFeedbackEligibility false")
            return false
        }
    }).catch((err) => {
        console.log(err)
    })
}

export function submitFeedback(feedbackData){
    return requestService.post(`/feedback`, feedbackData).then(response => {
        console.log(response)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}