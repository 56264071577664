import React, {useState, useEffect} from "react"
import { useLocation } from 'react-router-dom';
import "../css/profile.css"

import {getUserByUsername, getUserStats} from "../api/Users"

export default function Profile() {
    const [totalExperiences, setTotalExperiences] = useState(0)
    const [totalLikes, setTotalLikes] = useState(0)
    const [totalShares, setTotalShares] = useState(0)
    const [totalSaves, setTotalSaves] = useState(0)
    const [userRank, setUserRank] = useState("Amateur")
    const [userBio, setUserBio] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
    const [userName, setUserName] = useState("")
    const [userHandle, setUserHandle] = useState("")
    // const [userId, setUserId] = useState(null)
    const location = useLocation();
    
    const username = location.pathname.split("/")[1]
    console.log(username)




    useEffect(() => {
        // put some logic here to navigate to the /account page if the current user is the same as the user being viewed
        console.log("in useEffect")
        getUserByUsername(username).then(data => {
            setUserBio(data.bio)
            setUserPhoto(data.profile_photo)
            setUserRank(data.rank)
            setUserName(data.full_name)
            setUserHandle(data.username || "not set")
            getUserStats(data.id).then(data => {
                setTotalExperiences(data.total_experiences)
                setTotalLikes(data.total_likes)
                setTotalShares(data.total_shares)
                setTotalSaves(data.total_saves)
            })
        })
    }, [username])

    return (
        <div className="ui container">
            <div className="ui grid">
                <div className="eight wide column">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {userPhoto && <img src={userPhoto} alt={userName} className='profile-image' />}
                    </div>
                    <div className="ui button primary fluid">
                        Follow
                    </div>
                </div>
                <div className="eight wide column">
                    <div className="ui fluid card">
                        <div className="content">
                            <h2 style={{marginBottom: "0px"}}>{userName}</h2>
                            <h5 style={{marginTop: "3px"}}>@{userHandle}</h5>
                            <label className="ui green image label">{userRank}</label>
                            <div className="description" style={{ marginTop: '5px' }}>
                                <p style={{ padding: '10px', fontSize: '16px' }}>{userBio}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sixteen wide column">
                    <div className="ui fluid card">
                        <div className="content">
                            <div className="ui statistics" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                <div className="statistic">
                                    <div className="value">{totalExperiences}</div>
                                    <div className="label">Reviews</div>
                                </div>
                                <div className="statistic">
                                    <div className="value">{totalLikes}</div>
                                    <div className="label">Likes</div>
                                </div>
                                <div className="statistic">
                                    <div className="value">{totalShares}</div>
                                    <div className="label">Shares</div>
                                </div>
                                <div className="statistic">
                                    <div className="value">{totalSaves}</div>
                                    <div className="label">Saves</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
