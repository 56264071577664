import TokenService from '../utils/Token.jsx';
import { requestService } from '../utils/Utils.jsx';

export function login({email, password}){
    const formData = new URLSearchParams();
    formData.append('username', email); 
    formData.append('password', password);
    return requestService.post('/auth/login', formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export function register({full_name, email, username, password, verify_password}){
    const formData = new URLSearchParams();
    formData.append('full_name', full_name);
    formData.append('email', email);
    formData.append('username', username);
    formData.append('password', password);
    formData.append('verify_password', verify_password);
    console.log(formData)
    return requestService.post('/auth/register', formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => {
        console.log(response)
        return response
    }).catch((err) => {
        console.log(err)
    })
}

export function logout(){
    TokenService.clearToken();
    return requestService.post('/auth/logout').then(response => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}   