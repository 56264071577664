import React, { useContext } from "react"
import { Link } from 'react-router-dom';
import { AuthContext } from "../../App.jsx"



export default function RestaurantCard({ data }) {
    const { currentUser } = useContext(AuthContext);
    console.log("restaruant card data", data)

    const userExperienceLink = `/experience/${currentUser.id}/${data.id}`;

    return (
        <div className="ui container" style ={{paddingTop: "25px"}}>
            <div className="ui raised card fluid">
                <div className="content">
                    <div className="header">{data.name}</div>
                    <div className="meta">{data.address}</div>
                    <div className="description">
                    <p>{data.summary}</p>
                    <p>{Array.from({ length: data.price_level }, (_, i) => '$').join('')}</p>
                    </div>
                </div>
                <div className="extra content">
                    <div className="ui grid">
                        <div className="eight wide column">
                            <i className="phone icon"></i>{data.phone_number}
                        </div>
                        <div className="eight wide column">
                            <Link to={{pathname: userExperienceLink}} state={{data}}>
                                <button className="ui primary button fluid"> Create Experience with this Place</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}