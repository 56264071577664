import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import '../css/imageSlider.css';

export default function ImageDisplay({ photos }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const sliderRef = useRef(null);


  const responsive = {
      superLargeDesktop: {
          breakpoint: { max: 3000, min: 1600 },
          items: 4,
          imageContainerStyle: { height: "400px", width: "flex" },
      },
      desktop: {
          breakpoint: { max: 1599, min: 1200 },
          items: 3,
          imageContainerStyle: { height: "400px", width: "flex" },
      },
      tablet: {
          breakpoint: { max: 1199, min: 800 },
          items: 2,
          imageContainerStyle: { height: "400px", width: "flex" },
      },
      mobile: {
          breakpoint: { max: 799, min: 0 },
          items: 1,
          imageContainerStyle: { height: "400px", width: "flex" },
      },
  };

  const isSuperLargeDesktop = useMediaQuery({ query: '(min-width: 1600px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 800px)' });

  let responsiveKey;
  if (isSuperLargeDesktop) {
      responsiveKey = 'superLargeDesktop';
  } else if (isDesktop) {
      responsiveKey = 'desktop';
  } else if (isTablet) {
      responsiveKey = 'tablet';
  } else {
      responsiveKey = 'mobile';
  }

  useEffect(() => {
    const sliderElement = sliderRef.current;
    const handleTransitionEnd = () => {
      sliderElement.style.transition = '';
      sliderElement.removeEventListener('transitionend', handleTransitionEnd);
    };

    sliderElement.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      sliderElement.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, []);

  const handleNextImage = () => {
    if (currentImageIndex < photos.length - 1) {
        setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleTouchStart = (event) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    const endX = event.changedTouches[0].clientX;
    const deltaX = endX - startX;
    const threshold = 50; // Adjust as needed

    if (deltaX > threshold) {
      handlePrevImage();
    } else if (deltaX < -threshold) {
      handleNextImage();
    }
  };

  return (
    <div
      className="image-slider"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      ref={sliderRef}
    >
      <div key={currentImageIndex} style={{ ...responsive.carouselItemStyle, ...responsive[responsiveKey].imageContainerStyle, objectPosition: "center"}}>
        <img src={photos[currentImageIndex]} alt={`${currentImageIndex}`} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
      </div>
      <div className="slider-controls">
          <button
          className={`prev-button ${currentImageIndex === 0 ? 'disabled' : ''}`}
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
          >
            <i className="left arrow icon"></i>
          </button>
        <span className="image-index">{currentImageIndex + 1} / {photos.length}</span>
        <button
          className={`next-button ${currentImageIndex === photos.length - 1 ? 'disabled' : ''}`}
          onClick={handleNextImage}
          disabled={currentImageIndex === photos.length - 1}
        >
          <i className="right arrow icon"></i>
        </button>
      </div>
    </div>
  );
}
