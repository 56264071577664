// This file should have all the crud operations for the Restaurants Table
// import { requestService } from '../utils/Utils';
import { requestService } from '../utils/Utils.jsx';

export function getPlaces() {
    return requestService.get('/places');
}

export function getPlaceById(id) {
    console.log("getPlaceById", id)
    return requestService.get(`/places/${id}`).then(response => {
        return response.data
    }).catch(err => {
        console.log(err)
    });
}

export function createPlace(restaurant) {
    return requestService.post('/places', restaurant);
}

export function updatePlace(restaurant) {
    return requestService.put(`/places/${restaurant.id}`, restaurant);
}

export function deletePlace(id) {
    return requestService.delete(`/places/${id}`);
}

export const searchPlaces = (search, location) => {
    // requestService.config.headers.Authorization = `Bearer ${token}`;
    console.log("search", search)
    console.log("location", location)
    return requestService.post(`/places/search?q=${search}&location=${location.lat},${location.lng}`)
}