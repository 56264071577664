import React, {useContext} from 'react'
import { NavLink } from "react-router-dom";
import LoginRegisterCard from './cards/LoginRegisterCard.jsx';
import { AuthContext } from "../App.jsx"


export default function Header() {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <div className="ui container" style={{padding: "30px",}}>
            <div className="ui grid" style={{alignContent: "center"}}>
                <div className="sixteen wide column" style={{
                    textAlign: "center",
                }}>
                    <NavLink to="/"><h1 className="ui header">Eats By Jeets</h1></NavLink>
                </div>
                {!isAuthenticated ? (
                    <div className="sixteen wide column">
                    <LoginRegisterCard />
                </div>
            ) : null}
                {!isAuthenticated ? null :
                <div className="four wide column">
                    <NavLink to="/map"><i className='ui icon big compass' style={{color: "#000000"}}></i></NavLink>                    
                </div>}
                {!isAuthenticated ? null :
                    <div className="four wide column" style={{textAlign: 'center'}}>
                        <NavLink to="/saved"><i className="ui icon big bookmark" style={{color: "#000000"}}></i></NavLink>
                    </div>
                }
                {!isAuthenticated ? null : <div className={"four wide column"} style={{textAlign: 'center'}}>
                    <NavLink to="/feed"><i className='ui icon big home' style={{color: "#000000"}}></i></NavLink>
                </div>}
                {!isAuthenticated ? null :
                <div className="four wide column" style={{textAlign: 'center'}}>
                    <NavLink to="/account"><i className='ui icon big user circle' style={{color: "#000000"}}></i></NavLink>
                </div>}
                {/* {!isAuthenticated ?
                <div className="sixteen wide column" style={{textAlign: 'center'}}>
                    {error && <p>Authentication Error</p>}
                    {!error && isLoading && <p>Loading...</p>}
                    {!error && !isLoading && !isAuthenticated && <Auth0LoginWithRedirectButton />}
                </div> : null}  */}
            </div>
        </div>
    )
}