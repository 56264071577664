import React, { useState } from 'react'
import { searchPlaces } from '../api/Places'

export default function GoogleSearchBar({ location, updateSearchResults, setLoadingSpinner }) {
    const [search, setSearch] = useState("")

    const handleChange = (e) => setSearch(e.target.value)

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoadingSpinner(true)
        searchPlaces(search, location)
            .then(response => {
                let data = response.data
                setLoadingSpinner(false)
                updateSearchResults([...data])
            })
            .catch(err => {
              setLoadingSpinner(false)
              console.log(err)
            })
    }

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', paddingBottom: "25px" }}>
        <div className="ui icon input" style={{ position: 'relative', flex: 1 }}>
          <input
            type="text"
            placeholder="Search restaurants..."
            value={search}
            onChange={handleChange}
            style={{
                padding: '10px 40px 10px 20px',
                borderRadius: '5px',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                width: '100%',
            }}
          />
            <i className="search icon"></i>
        </div>
        <button
          type="submit"
          style={{
            backgroundColor: '#2b2d42',
            color: 'white',
            fontWeight: 'bold',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            marginLeft: '10px',
          }}
        >
          Search
        </button>
      </form>
    )
}