import { requestService } from '../utils/Utils.jsx';


export function likeExperience(experienceId){
    return requestService.post(`/interactions/${experienceId}/like`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function shareExperience(experienceId){
    return requestService.post(`/interactions/${experienceId}/share`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function saveExperience(experienceId){
    return requestService.post(`/interactions/${experienceId}/save`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function unlikeExperience(experienceId){
    return requestService.delete(`/interactions/${experienceId}/unlike`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function unsaveExperience(experienceId){
    return requestService.delete(`/interactions/${experienceId}/unsave`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}