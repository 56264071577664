// I want to create a component that displays any data I send to it in a list form.
// Path: frontend_2.0/src/components/DataList.jsx

import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';

export default function DataList({ dataObjectList, CardComponent, viewType, alignType, listType, deleteItem}){
    const [justify, setJustify] = useState('center')
    const [align, setAlign] = useState('center')
    const [list, setList] = useState('ui cards')
    const [view, setView] = useState('column')

    useEffect(() => {
        const alignmentUseEffect = () => {
            if(alignType === undefined){
                setAlign('center')
                setJustify('center')
            }
            else if(alignType === 'left'){
                setAlign('flex-start')
                setJustify('flex-start')
            }
            else if(alignType === 'right'){
                setAlign('flex-end')
                setJustify('flex-end')
            }
        }
    
        const listTypeUseEffect = () => {
            if(listType === undefined){
                setList('ui cards')
            }
            else if(listType === 'list'){
                setList('ui list')
            }
        }
    
        const viewUseEffect = () => {
            if(viewType === undefined){
                setView('column')
            }
            else if(viewType === 'row'){
                setView('row')
            }
        }
       alignmentUseEffect()
       listTypeUseEffect()
       viewUseEffect()
    }, [alignType, listType, viewType])

    if(dataObjectList.length === 0){
        return <h5>Nothing to see here.</h5>
    }
    else{
        return(
            <div className={list} style={{ display: 'flex', flexDirection: view, justifyContent: justify, alignItems: align }}>
                {dataObjectList.map((object, index) => <CardComponent key={index} data={object} deleteItem={deleteItem}/>)}
            </div>
        )
    }
}

DataList.propTypes = {
    dataObjectList: PropTypes.array.isRequired,
    CardComponent: PropTypes.func.isRequired
}




