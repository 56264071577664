import React, { useState, useEffect, useContext } from "react"
import { getAllExperiences } from "../api/Experiences"
import { AddExperienceButton } from "../components/buttons/AddExperienceButton"
import DataList from "../components/DataList"
import PostCard from "../components/cards/PostCard"
import { AuthContext } from "../App.jsx"


export default function Feed() {

    const { isAuthenticated } = useContext(AuthContext);
    const [feedData, setFeedData] = useState([])

    const deleteExperience = () => {
        console.log("delete experience")
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAllExperiences();
               //console.log("data in feed", data)
                setFeedData(data)
            } catch (err){
                console.log(err)
            }
        }
        fetchData();
    }, []);
    
    return (
        <div className="ui container" style={{"paddingRight": "20px"}}>
            {isAuthenticated && <AddExperienceButton feedData={feedData} setFeedData={setFeedData} style={{padding:"25px"}}/>}
            <DataList dataObjectList={feedData} CardComponent={PostCard} deleteItem={deleteExperience}/>
        </div>
    )
}