import React, { useState } from 'react'
import LoginForm from '../forms/LoginForm'
import RegisterForm from '../forms/RegisterForm'


export default function LoginRegisterCard() {

    const [loginToggle, setLoginToggle] = useState(true)
    const [registerToggle, setRegisterToggle] = useState(false)

    const handleToggleLogin = (loginBool) => {
        if(loginBool === true) {
            setLoginToggle(true)
            setRegisterToggle(false)
        }
        else{
            setLoginToggle(false)
            setRegisterToggle(true)
        }
    }

    if (loginToggle) {
        return (
            <div>
                <LoginForm />
                <div style={{textAlign: "center"}}>
                    <p>or <label href="#" onClick={() => handleToggleLogin(false)} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseLeave={(e) => e.target.style.textDecoration = 'none'}>Create an Account</label></p>
                </div>
            </div>
        )
    }
    else if (registerToggle) {
        return (
            <div>
                <RegisterForm />
                <div style={{textAlign: "center"}}>
                    <p>or <button style={{  backgroundColor: "transparent", border: "none", cursor: "pointer", textDecoration: "underline", display: "inline", margin: "0px", padding: "0px" }} onClick={() => handleToggleLogin(true)}>Login</button></p>
                        {/* <a href="#" onClick={() => handleToggleLogin(true)} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseLeave={(e) => e.target.style.textDecoration = 'none'}>Login</a></p> */}
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="ui buttons">
                <button className="ui button" onClick={() => handleToggleLogin(true)}>Existing User</button>
                <div className="or"></div>
                <button className="ui button" onClick={() => handleToggleLogin(false)}>Create an Account</button>
            </div>
        )
    }

}