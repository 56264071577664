import React, { useState, createContext, useEffect } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Feed from "./pages/Feed";
import Detail from "./pages/Detail";
import Profile from "./pages/Profile";
import Account from "./pages/Account";
import Search from "./pages/Search";
import Experience from "./pages/Experience";
import Saved from "./pages/Saved";
import Map from "./pages/Map";
import LoginRegisterCard from './components/cards/LoginRegisterCard';
import { checkUserLogin } from './utils/Utils';
import { getCurrentUser } from './api/Users';
import TokenService from './utils/Token';

export const AuthContext = createContext({});


function App(){

    return(
        <div>
            <Header/>
            <Routes>
                <Route path="/" element={<Feed />} />
                <Route path="/saved" element={<Saved/>} />
                <Route path="/feed" element={<Feed />} />
                <Route path="/account" element={<Account />} />
                <Route path="/explore" element={<Search />} />
                <Route path="/map" element={<Map/>} />
                <Route path="/experience/:uid/:id" element={<Detail />} />
                <Route path="/experiences/:id" element={<Experience />} />
                <Route path="/:username" element={<Profile />} />
            </Routes>
        </div>
    )
}

function AppWithAuth() {
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [showAuthPopup, setShowAuthPopup] = useState(false);
    let navigate = useNavigate()

    useEffect(() => {
        // check if token is in local storage
        //console.log("AppWithAuth useEffect")

        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker
        //       .register('/serviceworker.js')
        //       .then((registration) => {
        //         console.log('Service Worker registered:', registration);
        //       })
        //       .catch((error) => {
        //         console.log('Service Worker registration failed:', error);
        //       });
        //     }

        if (checkUserLogin()){
            // if token is in local storage, set current user and authenticated to true
            setAuthenticated(true);
            getCurrentUser().then(data => {
                setCurrentUser(data);
            });
        }
    }, []);

    function handleLogin(user){
        console.log("handleLogin user", user)
        setCurrentUser(user);
        setAuthenticated(true);
        setShowAuthPopup(false);
        // make request to backend to login
        
        // set token in local storage
    }

    function handleLogout(){
        console.log("logout called")
        TokenService.clearToken();
        if (checkUserLogin() === false){
            setCurrentUser(null);
            setAuthenticated(false);
            navigate("/");
        }
    }

    function handleShowAuthPopup(){
        setShowAuthPopup(true);
    }
    function handleCloseAuthPopup() {
        setShowAuthPopup(false);
    }

    return (
        <AuthContext.Provider
        value={{
            currentUser,
            isAuthenticated,
            loginContext: handleLogin,
            logoutContext: handleLogout,
            loginWithPopup: handleShowAuthPopup
        }}>
            <App/>
            {showAuthPopup && (
                <div className="ui dimmer modals page visible active">
                    <div className="ui standard modal active">
                        <i className="close icon" onClick={handleCloseAuthPopup}></i>
                        <div className="header">Login or Register</div>
                        <div className="content">
                            <LoginRegisterCard/>
                        </div>
                    </div>
                </div>
            )}
        </AuthContext.Provider>
    );
}


export default AppWithAuth