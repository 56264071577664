import React, { useState, useEffect } from 'react'
import GoogleSearchBar from '../components/GoogleSearchBar'
import DataList from '../components/DataList'
import RestaurantCard from '../components/cards/RestaurantCard'

export default function Search() {
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [searchResults, setSearchResults] = useState([])
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    // const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const location = {
        lat: Number(latitude),
        lng: Number(longitude),
    }

    const updateSearchResults = (results) => {
        setSearchResults(results)
    }

    useEffect(() =>{
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                setLatitude(position.coords.latitude)
                setLongitude(position.coords.longitude)
                console.log(position.coords.latitude, position.coords.longitude)
                });
            }
            else{
                alert("Location must be enabled for search capabilities.")
            }
    }, [])

    return (
        <div className="ui container">
            <h3>Where did you have this experience?</h3>
            <GoogleSearchBar location={location} updateSearchResults={updateSearchResults} setLoadingSpinner={setLoadingSpinner}/>
            {loadingSpinner && <div className="ui active centered inline loader"></div>}
            <DataList dataObjectList={searchResults} CardComponent={RestaurantCard}/>
        </div>
    )
}