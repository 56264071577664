import { requestService } from '../utils/Utils.jsx';

export function getComments(experienceId){
    return requestService.get(`/comments/${experienceId}`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export async function createComment(experienceId, comment){
    console.log(experienceId, comment)
    return requestService.post(`/comments/${experienceId}`, comment).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function deleteComment(commentId){
    return requestService.delete(`/comments/${commentId}`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}
