import React, {useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { createExperience } from "../../api/Experiences";
import { AuthContext } from '../../App';

export default function ExperienceForm({placeData, images}){
    const { currentUser, isAuthenticated } = useContext(AuthContext);
    const [overview, setOverview] = useState("");
    const [vibe, setVibe] = useState("");
    const [vibeRating, setVibeRating] = useState("");
    const [order, setOrder] = useState("");
    const [orderRating, setOrderRating] = useState("");
    const [eyeCatching, setEyeCatching] = useState("");
    const [eyeCatchingRating, setEyeCatchingRating] = useState("");
    const [service, setService] = useState("");
    const [serviceRating, setServiceRating] = useState("");
    const [postKey, setPostKey] = useState("");
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [errorBool, setErrorBool] = useState(false);
    let navigate = useNavigate()

    useEffect(() => {
      const postKey = String(Math.random().toString(36).substring(2, 15))
      setPostKey(postKey)
    }, [placeData, images])

    async function computeAverageRating(){
        var sum = parseInt(vibeRating) + parseInt(orderRating) + parseInt(eyeCatchingRating) + parseInt(serviceRating);
        var average = sum / 4;
        console.log("computeAverageRating", average)
        return average;
    }

    async function handleSubmit(event){ // Example on how to get the token and make a request with it
      if(isAuthenticated){
        setLoadingSpinner(true)
        const now = new Date();
        console.log("handleSubmit", now)
        event.preventDefault();
        const formData = new FormData();
        const averageRating = await computeAverageRating()
        try {
            formData.append("restaurant_name", placeData.name)
            formData.append("uid", currentUser.id)
            formData.append("place_id", placeData.place_id)
            formData.append("username", currentUser.username)
            formData.append("user_review", overview)
            formData.append("user_rating", averageRating)
            formData.append("vibe", vibe)
            formData.append("vibe_rating", vibeRating)
            formData.append("ordered", order)
            formData.append("ordered_rating", orderRating)
            formData.append("service", service)
            formData.append("service_rating", serviceRating)
            formData.append("eye_catching", eyeCatching)
            formData.append("eye_catching_rating", eyeCatchingRating)
            formData.append("timestamp", now.toISOString())


            // formData.append("photo", images[0])
            if (images.length > 0) {
                console.log("images", images)
                images.forEach((file, index) => {
                    console.log("File in for loop", index, file);
                    if (file instanceof File) {
                        console.log("file is instance of File")
                        const renamedFile = new File([file], `${postKey}_photo_${index}`);
                        formData.append(`photos`, renamedFile);
                    } else {
                        console.log(`Invalid file at index ${index}`);
                    }
                });
            }

            const formDataObject = Object.fromEntries(formData.entries());
            console.log(formDataObject)

            createExperience(formData).then((response) => {
                console.log("response from server", response)
                if (response === "error"){
                    setErrorBool(true)
                    setLoadingSpinner(false)
                }
                if (response.status === 200){
                    navigate("/feed")
                }
            })
        }
        catch (error){
          // Handle error
          console.error('error', error);
          setErrorBool(true)
          setLoadingSpinner(false)
        };
      }
    }

    const RatingSelector = ({rating, onRate}) => {    
        const handleRate = (value) => {
            onRate(value);
        }
        
        return (
            <div className="rating-selector" style={{ display: "inline-block", paddingBottom: "5px"}}>
            {[...Array(5)].map((_, index) => {
              const value = index + 1;
              return (
                <i
                  key={index}
                  className={`icon large star ${rating >= value ? "yellow" : "outline"}`}
                  onClick={() => handleRate(value)}
                ></i>
              );
            })}
          </div>
        );
      };

    if (isAuthenticated) {
    return(
      <div className="ui container">
        <form onSubmit={handleSubmit} className="ui form" style={{ maxWidth: "600px", margin: "0 auto", padding: "20px", borderRadius: "5px" }}>
        <div className="field">
          <label>Title</label>
          <input type="text" name="overview" value={overview} onChange={(e) => setOverview(e.target.value)} />
        </div>
  
        <div className="field">
          <label>The Vibe</label>
          <RatingSelector rating={vibeRating} onRate={setVibeRating} />
          <input type="text" name="vibe" value={vibe} onChange={(e) => setVibe(e.target.value)} />
        </div>
  
        <div className="field">
          <label>The Food</label>
          <RatingSelector rating={orderRating} onRate={setOrderRating} />
          <input type="text" name="order" value={order} onChange={(e) => setOrder(e.target.value)} />
        </div>
  
        <div className="field">
          <label>The Service</label>
          <RatingSelector rating={serviceRating} onRate={setServiceRating} />
          <input type="text" name="service" value={service} onChange={(e) => setService(e.target.value)} />
        </div>

        <div className="field">
          <label>The Eye Catching</label>
          <RatingSelector rating={eyeCatchingRating} onRate={setEyeCatchingRating} />
          <input type="text" name="eyeCatching" value={eyeCatching} onChange={(e) => setEyeCatching(e.target.value)} />
        </div>

        <div className="right floated" style={{ marginTop: "20px" }}>
          <button type="submit" className="ui blue button">
            Create
          </button>
        </div>
      </form>
      {loadingSpinner && (
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Creating experience</div>
        </div>
      )}
      {errorBool && (
        <div className="ui negative message">
          <i className="close icon" onClick={() => setErrorBool(false)}></i>
          <div className="header">Error creating experience</div>
          <p>There was an error while attempting to create the experience. Please try again later.</p>
        </div>
      )}
      </div>
    )
    }
}