import React, {useState, useEffect, useContext} from "react"
import { AuthContext } from "../App";
import { getCurrentUser, updateUserData, updateUserPhoto, getUserStats } from "../api/Users";
import "../css/profile.css"
import { checkUserLogin } from "../utils/Utils";


export default function Account() {
    const [totalExperiences, setTotalExperiences] = useState(0)
    const [totalLikes, setTotalLikes] = useState(0)
    const [totalShares, setTotalShares] = useState(0)
    const [totalSaves, setTotalSaves] = useState(0)
    const [userRank, setUserRank] = useState("Amateur")
    const [userBio, setUserBio] = useState("no bio")
    const [userPhoto, setUserPhoto] = useState("/images/placeholder.png")
    const [userFullName, setUserFullName] = useState("")
    const [userName, setUserName] = useState("")
    const [isEditing, setIsEditing] = useState(false);
    // const [selectedPhoto, setSelectedPhoto] = useState(null);
    const { currentUser, isAuthenticated, logoutContext } = useContext(AuthContext);

    // const tempPhoto = "/images/placeholder.png"

    // const totalFollowers = "32k"
    // const totalFollowing = "0"

    function handleLogout(){
        logoutContext();
    };

    useEffect(() => {
        if (checkUserLogin()){
            if (!isAuthenticated || !currentUser) {
                return;
            }
            getCurrentUser().then(data => {
                //console.log(data)
                setUserBio(data.bio)
                if (data.profile_photo){
                    //console.log(data.profile_photo, " exists!")
                    setUserPhoto(data.profile_photo)
                }
                setUserRank(data.rank)
                setUserFullName(data.full_name)
                setUserName(data.username || "not set")
            })
            if (currentUser) {
                getUserStats(currentUser.id).then(data => {
                    setTotalExperiences(data.total_experiences)
                    setTotalLikes(data.total_likes)
                    setTotalShares(data.total_shares)
                    setTotalSaves(data.total_saves)
                })
            }
        }
    }, [currentUser, isAuthenticated]);

    // useEffect(() => {
    //     if (selectedPhoto) {
    //         setUserPhoto(selectedPhoto);
    //     }
    // }, [selectedPhoto]);
    
    const handleProfileEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // setSelectedPhoto(null)
    };
    
    const handleProfileSaveClick = () => {
        setIsEditing(false);
        updateUserData(currentUser.id, {
            "username": userName,
            "full_name": userFullName,
            "bio": userBio
        });
    };

    const handleBioChange = (event) => {
        setUserBio(event.target.value);
    };

    if (!isAuthenticated) {
      return <div>You are not logged in.</div>;
    }
    else if (isAuthenticated && !currentUser) {
        return <div>We can't get the user for some reason...</div>;
    }
    else if (isAuthenticated && currentUser) {
        return (
            <div className="ui container">
                <div className="ui grid">
                    <div className="eight wide column">
                        {/* <ProfilePicture userPhotoFromServer={userPhoto} updateUserPhoto={updateUserPhoto}/> */}
                        <SimpleProfilePicture existingPhoto={userPhoto} setUserPhoto={setUserPhoto}/>
                    </div>
                    <div className="eight wide column">
                        <div className="ui fluid card">
                            <div className="content">
                                {isEditing ? (
                                <input
                                    type="text"
                                    value={userFullName}
                                    onChange={e => setUserFullName(e.target.value)}
                                    style={{ fontSize: '24px', marginBottom: '3px' }}
                                />
                                ) : (
                                <h2 style={{ marginBottom: '0px' }}>{userFullName}</h2>
                                )}
                                {isEditing ? (
                                <input
                                    type="text"
                                    value={userName}
                                    onChange={e => setUserName(e.target.value)}
                                    style={{ fontSize: '18px', marginTop: '3px' }}
                                />
                                ) : (
                                <h5 style={{ marginTop: '3px' }}>@{userName}</h5>
                                )}
                                <label className="ui green image label">{userRank}</label>
                                <div className="description" style={{ marginTop: '5px' }}>
                                {isEditing ? (
                                    <>
                                    <textarea
                                        style={{
                                        padding: '10px',
                                        fontSize: '16px',
                                        width: '100%',
                                        height: '50%',
                                        boxSizing: 'border-box',
                                        resize: 'none',
                                        }}
                                        value={userBio}
                                        onChange={handleBioChange}
                                    />
                                    <div>
                                        <button className="ui basic primary compact small button" onClick={handleProfileSaveClick}>
                                        Save
                                        </button>
                                        <button className="ui basic secondary compact small button" onClick={handleCancelClick}>
                                        Cancel
                                        </button>
                                    </div>
                                    </>
                                ) : (
                                    <>
                                    <p style={{ padding: '10px', fontSize: '16px' }}>{userBio}</p>
                                    <button className="ui basic secondary compact small button" onClick={handleProfileEditClick}>
                                        <i className="pencil icon"></i>
                                    </button>
                                    </>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sixteen wide column">
                        <div className="ui fluid card">
                            <div className="content">
                                <div className="ui statistics" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                    <div className="statistic">
                                        <div className="value">{totalExperiences}</div>
                                        <div className="label">Reviews</div>
                                    </div>
                                    <div className="statistic">
                                        <div className="value">{totalLikes}</div>
                                        <div className="label">Likes</div>
                                    </div>
                                    <div className="statistic">
                                        <div className="value">{totalShares}</div>
                                        <div className="label">Shares</div>
                                    </div>
                                    <div className="statistic">
                                        <div className="value">{totalSaves}</div>
                                        <div className="label">Saves</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sixteen wide column">
                        <button className="ui seconday button" onClick={handleLogout} style={{ 
                                backgroundColor: '#2b2d42', 
                                color: 'white', 
                                fontWeight: 'bold', 
                                padding: '10px 20px', 
                                borderRadius: '5px', 
                                border: 'none', 
                                display: 'block', 
                                margin: '0 auto',
                                marginBottom: '25px',
                            }}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

function SimpleProfilePicture({existingPhoto, setUserPhoto}){
    const [profilePicture, setProfilePicture] = useState(existingPhoto)
    const [newProfilePicture, setNewProfilePicture] = useState(profilePicture)
    const [photoSelected, setPhotoSelected] = useState(false)
    const [toggleEdit, setToggleEdit] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        setProfilePicture(existingPhoto);
    }, [existingPhoto]);

    const handleImageSelect = (event) => {
        const selectedPicture = event.target.files[0];
        if (selectedPicture) {
            const reader = new FileReader();
            reader.onload = () => {
                const imageDataUrl = reader.result;
                setNewProfilePicture(imageDataUrl);
                setPhotoSelected(true);
                setToggleEdit(true);
            };
            reader.readAsDataURL(selectedPicture);
            setSelectedFile(selectedPicture); // Store the original file
        }
    };

    const handleEditting = () => {
        setToggleEdit(true);
    }

    const handleCancel = () => {
        setToggleEdit(false);
        setNewProfilePicture(profilePicture);
    }

    const handleUpload = () => {
        setToggleEdit(false);
        const formData = new FormData();
        formData.append("photo", selectedFile);
        updateUserPhoto(formData).then((data) => {
            console.log("updated profile picture data", data)
            setProfilePicture(data);
            setUserPhoto(data);
        })
    }

    return (
        <div>
            {!toggleEdit ? (<div className="ui fluid image">
                <img className="ui circular image" style={{ width: "300px" }}  src={profilePicture} alt="profile" />
            </div>) : null}
            {toggleEdit ? (
                <div>
                    <div className="ui fluid image">
                        <img className="ui circular image" style={{ width: "300px" }}  src={newProfilePicture} alt="profile" />
                    </div>
                    <div style={{paddingTop: "20px", display: "flex"}}>
                        <label htmlFor="imageInput" className="ui basic primary compact small button" style={{ cursor: 'pointer' }}>
                            <i className="file icon"></i>
                            Select
                            Photo
                        </label>
                        <input
                            id="imageInput"
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={handleImageSelect}
                            style={{ display: 'none'}}
                        />
                    </div>
                </div>
            ): null}
            {toggleEdit &&  photoSelected ? (
                <div>
                    <button className="ui basic primary compact small button" onClick={handleUpload}>
                        <i className="upload icon"></i>
                        Upload
                    </button>

                    <button className="ui basic secondary compact small button" onClick={handleCancel}>
                        <i className="cancel icon"></i>
                        Cancel
                    </button>
                </div>
            )
             :(
                <button className="ui basic secondary compact small button" onClick={handleEditting}>
                    <i className="pencil icon"></i>
                </button>
             )   
            }
        </div>
    )

}