// This file should have all the crud operations for the Users table
import { requestService } from '../utils/Utils.jsx';

// export function getUserData(token){
//     let response = requestService.get('userinfo').then((data) => {
//         console.log(data)
//         return data
//     }).catch((err) => {
//         console.log(err)
//     })
//     return response
// }

export async function getUserByUsername(userName){
    return await requestService.get(`/users/${userName}`).then((response) => {
        console.log(response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function getCurrentUser(){
    return requestService.get('/users/me').then(response => {
        // console.log("data from /users/me", response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    });
}


export function updateUserData(userId, userData){
    return requestService.put(`/users/${userId}`, userData).catch((err) => {console.log(err)});
}

export function updateUserPhoto(photoFormData){
    console.log(photoFormData, typeof(photoFormData))
    return requestService.post(`/users/photo`, photoFormData).then(response => {
        console.log('File uploaded successfully');
        return response.data
    }).catch((err) => {
        console.log(err)
    });
}

export function getUserStats(userId){
    return requestService.get(`/users/${userId}/stats`).then(response => {
        console.log(`data from /users/${userId}/stats`, response.data)
        return response.data
    }).catch((err) => {
        console.log(err)
    });
}