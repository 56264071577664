import React, { useState, useContext } from "react"
import { login } from "../../api/Auth";
import "../../css/form.css"
import { getCurrentUser } from "../../api/Users";
import TokenService from "../../utils/Token";
import { AuthContext } from "../../App.jsx"

export default function LoginForm() {
    const { loginContext } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [failure, setFailure] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  
    function handleLogin(event) {
        event.preventDefault();
        if (email === "" || password === "") {
            setErrorMessage("Email or password is empty")
            setFailure(true);
        }
        else{
          login({email, password}).then((response) => {
            if (response.status === 200) {
                TokenService.setToken(response.data)
                getCurrentUser().then(data => {
                    loginContext(data)
                });
            }
            else {
                setErrorMessage("Email or password is incorrect")
                setFailure(true);
            }
          })
        }
        
    }

    function handleFailureClose(){
        setFailure(false);
    }
  
    return (
      <form className="form" onSubmit={handleLogin}>
        <h2>Login</h2>
        <div className="label">Email</div>
        <input className="input" type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} />
        <div className="label">Password</div>
        <input className="input" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
        <button className="button" type="submit">
          Login
        </button>
        {failure && <div className="ui error message"><i class="close icon" onClick={() => handleFailureClose()}></i>{errorMessage} </div>}
      </form>
    );
  }
  