import React, {useEffect, useState, useContext} from "react"
import { useLocation } from 'react-router-dom';
import ExperienceForm from "../components/forms/ExperienceForm"
import RestaurantDetailCard from "../components/cards/RestaurantDetailCard"
import { AuthContext } from '../App.jsx';
import "../css/image.css"


export default function Detail() {
    const [imageList, setImageList] = useState([]);
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();
    const data = location.state.data;

    useEffect(() => {
        console.log("image list", imageList)
    }, [imageList])
    
    // show restaurant details
    // also shouw the experience review form here
    // when the user submits the form, it should send the data to the backend
    // and then redirect the user to the feed page, displaying the user's new experience

    return (
        <div className="ui stackable grid" style={{margin: "20px"}}>
            <div className="eight wide column">
                <div>
                    <RestaurantDetailCard data={data}/>
                </div>
                <div>
                    {/* <div className="ui container fluid"> */}
                        <div className="ui raised fluid card" style={{ marginTop: "25px", padding: "20px", borderRadius: "5px" }}>
                            
                            {isAuthenticated ?
                                (<div style={{ marginTop: "5px" }}>
                                    <MultiFileUploader imageList={imageList} setImageList={setImageList}/>
                                </div>): null}
                        
                            <div className="ui small images" style={{marginTop: "20px"}}>
                                {imageList.map((image, index) => (
                                    <img
                                        key={index}
                                        src={URL.createObjectURL(image)}
                                        alt="uploaded"
                                        style={{
                                            width: "150px",
                                            height: "150px",
                                            objectFit: "cover",
                                            borderRadius: "5px",
                                            marginRight: "10px",
                                            objectPosition: "center"
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
            <div className="eight wide column">
                <ExperienceForm placeData={data} images={imageList}/>
            </div>
        </div>
    )
}


function MultiFileUploader({ imageList, setImageList }) {


    const handleImageSelect = (e) => {
        const selectedImages = e.target.files;
    
        if (selectedImages) {
          const updatedImageList = [];
    
          for (let i = 0; i < selectedImages.length; i++) {
            const file = selectedImages[i];
            const reader = new FileReader();
    
            reader.onload = () => {
              const imageDataURL = reader.result;
              const imageObject = new Image();
              imageObject.src = imageDataURL;
              updatedImageList.push(imageObject);
            };
            reader.readAsDataURL(file);
            setImageList((prevImageList) => [...prevImageList, file]);
          }
        }
      };
    return (
        <>
                <label
                    htmlFor="imageInput"
                    className="ui secondary icon fluid button"
                    style={{ cursor: 'pointer', height: "40px", width: "flex", display: "flex", justifyContent: "center", alignItems: "center"}}
                >
                    <i className="file icon"></i>
                    Upload Images
                </label>
                <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageSelect}
                    style={{ display: 'none' }}
                />
        </>
    )
}
