import React from 'react'

export default function RestaurantDetailCard({ data }) {

    return (
        <div className="ui container fluid" style={{ paddingTop: "20px"}}>
            <div className="ui raised fluid card" style={{ margin: "0 auto", padding: "20px", borderRadius: "5px" }}>
                <div className="content">
                    <div className="header">{data.name}</div>
                    <div className="meta">{data.address}</div>
                    <div className="description">
                    <p>{data.summary}</p>
                    <p>{Array.from({ length: data.price_level }, (_, i) => '$').join('')}</p>
                    </div>
                </div>
                <div className="extra content">
                    <div className="ui grid">
                    <div className="eight wide column">
                        <i className="phone icon"></i>{data.phone_number}
                    </div>
                    <div className="eight wide column">
                        <i className="star icon"></i>{data.rating} based on {data.user_ratings_total} reviews
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}