import React, {useEffect, useState, useContext} from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import ExperienceCard from "../components/cards/ExperienceCard"
import { getExperienceById } from "../api/Experiences";
import { getComments } from "../api/Comments";
import { getPlaceById } from "../api/Places";

import { AuthContext } from '../App.jsx';
import CommentCard from "../components/cards/CommentCard";
import CommentForm from "../components/forms/CommentForm";
import ImageDisplay from "../components/ImageDisplay";
import { deleteComment } from '../api/Comments'

import RestaurantDetailCard from "../components/cards/RestaurantDetailCard";

export default function Experience() {

    const { currentUser, isAuthenticated } = useContext(AuthContext);    
    const [experienceData, setExperienceData] = useState(null)
    const [commentList, setCommentList] = useState([])
    const [restaurantData, setRestaurantData] = useState(null)
    const location = useLocation();
    const navigate = useNavigate()
    console.log(location)
    const data = location.state.data
    console.log("post data", data)
    
    // console.log("experience data", experienceData)

    // get experience data from backend

    if(data === null) {
        // use location.data to get experience data

    }

    const removeCommentFromList = (commentId) => {
        console.log("deleting comment", commentId)
        setCommentList(commentList.filter((comment) => comment.id !== commentId))
        deleteComment(commentId)
    }

    const handleBack = () => {
        navigate(-1)
      };

    useEffect(() => {
        console.log("useEffect in experience to get restaruant data")
        const fetchRestaurantData = async () => {
            try {
                console.log("restaurant rid", data.rid)
                const restaurantData = await getPlaceById(data.rid);
                console.log("fetched Restaurant data", restaurantData)
                setRestaurantData(restaurantData)
            } catch (err){
                console.log(err)
            }
        }
        const fetchData = async () => {
            try {
                const expData = await getExperienceById(data.id);
                console.log("data in feed", expData)
                setExperienceData(expData)
            } catch (err){
                console.log(err)
            }
        }
        const fetchCommentData = async () => {
            try {
                const commentData = await getComments(data.id);
                console.log("comment data in feed", commentData)
                setCommentList(commentData)
            } catch (err){
                console.log(err)
            }
        }
        fetchCommentData();
        fetchData();
        fetchRestaurantData();
    }, [data]);

    return (
        <div className="ui container">
            <button className="ui button basic blue" onClick={handleBack}>Back</button>
            {restaurantData ? <RestaurantDetailCard data={restaurantData}/> : <div>Loading...</div>}
            <div className="ui stackable grid">
                <div className="eight wide column">
                    {data.photos.length > 0 ? (
                        <div className="ui container" style={{padding: "20px"}}>
                            <ImageDisplay photos={data.photos}/>
                        </div>
                    ): null }
                 <div className="ui container" style={{paddingTop: "25px", paddingBottom: "100px"}}>
                    {!isAuthenticated ? <p>Log in to like, share, and save</p> :
                        <CommentForm experienceId={data.id} userId={currentUser} commentList={commentList} setCommentList={setCommentList} />
                    }
                    {commentList.length > 0 ?
                    <div className="content">
                        {/* <DataList dataObjectList={commentList} CardComponent={CommentCard} viewType="column" alignType="left" listType="list" deleteItem={removeCommentFromList} /> */}
                        {commentList.map((comment) => {return <CommentCard data={comment} key={comment.id} deleteItem={removeCommentFromList}/>})}
                    </div> : null
                    }
                </div>
                </div>
                <div className="eight wide column">
                    {data ? <ExperienceCard data={experienceData}/> : <div>Loading...</div>}
                </div>
            </div>
        
        </div>
    )
}