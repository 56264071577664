import React from 'react'
import { useLocation } from 'react-router-dom';


export default function ExperienceCard() {
  const location = useLocation();
  const data = location.state.data;

  return (
    <div className="ui container">
        <br />
        <div className="ui raised card fluid">
            <div className="content" style={{marginLeft: "10px", marginRight: "10px"}}>
                <img className="ui tiny circular image left floated" src={data.profile_photo} alt="profile"/>
                <h3>@{data.username}</h3>
                <h2><u>{data.restaurant_name}</u></h2>
                <div className="ui grid" style={{marginLeft: "10px", marginRight: "10px"}}>
                    <div className="row">
                        <div className="ten wide column left floated">
                            <h3>Overview</h3>
                            <p>{data.user_review}</p>
                        </div>
                        <div className="six wide column right floated">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h3 style={{margin: "0px", paddingRight: "15px"}}>{data.user_rating}</h3>
                                <i className="star icon large yellow"></i>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="ten wide column left floated">
                            <h3>The Food</h3>
                            <p>{data.ordered}</p>
                        </div>
                        <div className="six wide column right floated">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <h3 style={{margin: "0px", paddingRight: "15px"}}>{data.ordered_rating}</h3>
                            <i className="star icon large yellow"></i>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="ten wide column left floated">
                                <h3>The Vibe</h3>
                                <p>{data.vibe}</p>
                        </div>
                            <div className="six wide column right floated">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <h3 style={{margin: "0px", paddingRight: "15px"}}>{data.vibe_rating}</h3>
                                    <i className="star icon large yellow"></i>
                                </div>
                            </div>
                        
                    </div>
                    <br />
                    <div className="row">
                        <div className="ten wide column left floated">
                            <h3>The Service</h3>
                            <p>{data.ordered}</p>
                        </div>
                        <div className="six wide column right floated">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <h3 style={{margin: "0px", paddingRight: "15px"}}>{data.service_rating}</h3>
                            <i className="star icon large yellow"></i>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="ten wide column left floated">
                                <h3>The Eye Catching</h3>
                                <p>{data.eye_catching}</p>
                        </div>
                        <div className="six wide column right floated">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h3 style={{margin: "0px", paddingRight: "15px"}}>{data.eye_catching_rating}</h3>
                                <i className="star icon large yellow"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </div>
  )
}