import React, {useState, useEffect, useContext} from 'react'
import CommentForm  from '../forms/CommentForm';
import { Link } from 'react-router-dom';
import CommentCard from './CommentCard';
import moment from 'moment';
import "../../css/card.css"
import "../../css/shareAnimation.css"
import { AuthContext } from "../../App.jsx"
import { deleteComment } from '../../api/Comments';
import { likeExperience, saveExperience, shareExperience, unlikeExperience, unsaveExperience } from '../../api/Interactions';
import ImageDisplay from './../ImageDisplay';


export default function PostCard({ data }) {
    const [like, toggleLike] = useState(false)
    const [likeList, setLikeList] = useState(data.likes)
    const [share, toggleShare] = useState(false)
    const [photolist] = useState(data.photos)
    const [save, toggleSave] = useState(false)
    const [saveList, setSaveList] = useState(data.saves)
    const [userName] = useState(data.username)
    const [commentList, setCommentList] = useState(data.comments)
    const { currentUser, isAuthenticated, loginWithPopup } = useContext(AuthContext);
    const [isClickedLike, setIsClickedLike] = useState(false);
    const [isClickedSave, setIsClickedSave] = useState(false);
    const [isClickedShare, setIsClickedShare] = useState(false);
    const [userPicture] = useState(data.profile_photo)

    const timeAgo = moment(data.created_at).fromNow();

    let experienceLink = "/experiences/" + data.id

    useEffect(() => {
        if(currentUser){
            if (data.likes.indexOf(currentUser.id) > -1){
                toggleLike(true)
            }
            if (data.saves.indexOf(currentUser.id) > -1){
                toggleSave(true)
            }
        }
    }, [currentUser, data.likes, data.saves])

    const removeCommentFromList = (commentId) => {
        console.log("deleting comment", commentId)
        setCommentList(commentList.filter((comment) => comment.id !== commentId))
        deleteComment(commentId)
    }

    const likePost = () => {
        if(isAuthenticated){
            setIsClickedLike(!isClickedLike)
            if(like === true){ // if user has already liked the post, unlike it
                toggleLike(!like)
                setLikeList(data.likes.filter((item) => item !== currentUser.id))
                unlikeExperience(data.id)
            } else{
                toggleLike(!like)
                setLikeList([...likeList, currentUser.id])
                likeExperience(data.id)
            }
            // console.log("like post")
        }
        else{
            // console.log("Log in to save")
            loginWithPopup()
        }
    }

    const sharePost = () => {
        if(isAuthenticated){
            setIsClickedShare(!isClickedShare);
            const shareLink = "https://eatsbyjeets.com/" + experienceLink
            navigator.clipboard.writeText(shareLink)
            shareExperience(data.id)
            toggleShare(!share)
        }
        else{
            loginWithPopup()
        }
    }

    const savePost = () => {
        // console.log("save post")
        // This function should save this restaurant to the user's saved list
        // if it is a place they have already been to, it should be added to their "saved" list
        // if it is a place they have not been to, it should be added to their "want to go" list
        if(isAuthenticated){
            setIsClickedSave(!isClickedSave);
            if(save === true){
                toggleSave(!save)
                setSaveList(data.saves.filter((item) => item !== currentUser.id))
                unsaveExperience(data.id)
            } else{
                toggleSave(!save)
                setSaveList([...saveList, currentUser.id])
                saveExperience(data.id)
            }
        }
        else{
            // console.log("Log in to save")
            loginWithPopup()
        }
    }

    // when returning post data, I need user's photo, username, and user id


    return (
        <div className="ui card" style={{minWidth: "300px", width: "500px"}}>
            <div className="ui content">
                <img className="ui avatar image" src={userPicture} alt="profile"/>
                <Link to={`/${userName}`} style={{color: "#000000"}}>
                    <b>@{userName}</b>
                </Link>
                <div className="ui right floated meta">{timeAgo}</div>
            </div>
            <div className="ui content">
                <div className="restaurant-wrapper">
                    <div className="ui grid">
                        <div className="row"> 
                            <div className="twelve wide column">
                                <h2>{data.restaurant_name}</h2>
                            </div>
                            <div className="four wide column" style={{padding: "0px", alignContent: "center"}}>
                            <h4>{data.user_rating} <i className="ui star icon" style={{ color: "#F1C644" }}></i></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ui content" style={{ paddingBottom: "20px"}}>
                    {photolist.length > 0 ? <ImageDisplay photos={photolist}/> : null}
                </div>
                <div className="ui grid">
                    <div className="row" style={{paddingLeft: "20px", paddingTop: "2px", paddingBottom: "5px"}}>
                        <p>{data.user_review}</p>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="ui grid" style={{paddingTop: "10px", paddingBottom: "15px", justifyContent: "space-between"}}>
                    <div className="button-wrapper">
                        <button onClick={() => likePost()} className={isClickedLike ? 'scale-up-animation' : ''}>
                            {!like ? <i className="heart outline icon"></i> : <i className="heart icon"></i> }
                            <span>{likeList.length}</span>
                        </button>
                        <button onClick={() => savePost()} className={isClickedSave ? 'scale-up-animation' : ''}>
                            {!save ? <i className="bookmark outline icon"></i> : <i className="bookmark icon"></i> }
                            <span>{saveList.length}</span>
                        </button>
                        <button onClick={() => sharePost()} className={isClickedShare ? 'scale-up-animation' : ''}>
                            <i className="share square icon"></i>
                            {/* <span>{shareList.length}</span> */}
                        </button>
                    </div>
                    <div className="right floated meta">
                        <Link to={{ pathname: experienceLink }} state={{data}}>
                            <b style={{color: "#000000"}}>View Rec <i className="ui right arrow icon"></i></b>
                        </Link>
                    </div>
                </div>
            {!isAuthenticated ? <p>Log in to like, share, and save</p> :
                <CommentForm experienceId={data.id} userId={currentUser} commentList={commentList} setCommentList={setCommentList} />
            }
            </div>  
            {commentList.length > 0 ? (
                commentList.length >= 3 ? (
                    <div className="content">
                        <Link to={{ pathname: experienceLink }} state={{ data }}>
                            View all {commentList.length} comments
                        </Link>
                    </div>
                ) : (
                    <div className="content">
                        {commentList.map((comment) => (
                            <CommentCard
                                key={comment.id}
                                data={comment}
                                deleteItem={removeCommentFromList}
                            />
                        ))}
                    </div>
                )
            ) : null}
        </div>
    )
}
