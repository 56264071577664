import React, {useState, useEffect, useContext} from 'react'
import { AuthContext } from "../../App.jsx"
import { createComment } from "../../api/Comments.jsx"
import "../../css/commentForm.css"

export default function CommentForm({ experienceId, commentList, setCommentList }) {
  const { currentUser } = useContext(AuthContext);
  const [submitButtonToggle, setSubmitButtonToggle] = useState(true); // Get only the setter function
  const [comment, setComment] = useState("");

  async function handleSubmit(event) {
    if (comment !== "") {
      event.preventDefault();
      console.log("CommentForm user", currentUser.id);
      console.log(comment + " on " + experienceId);

      const payload = { text: comment };
      const responseData = await createComment(experienceId, payload);

      const updatedComments = [
        ...commentList,
        {
          username: currentUser.username,
          profile_photo: currentUser.profile_photo,
          post_id: experienceId,
          text: comment,
          id: responseData.id,
        },
      ];
      setCommentList(updatedComments);

      setComment("");
    }
  }

  useEffect(() => {
    if (comment.length > 0) {
      setSubmitButtonToggle(true);
    } else {
      setSubmitButtonToggle(false);
    }
  }, [comment]);

  return (
    <div className="ui form" style={{ padding: "0px", outline: "hidden", border: "none" }}>
      <form style={{ padding: "0px", outline: "hidden", border: "none" }}>
        <div
          className="ui fluid small transparent input"
          style={{ padding: "0px", display: "flex", outline: "hidden", width: "100%" }}
        >
          <textarea
            style={{
              resize: "none",
              overflow: "auto",
              outline: "hidden",
              minHeight: "20px",
              maxHeight: "50px",
            }}
            rows="1"
            value={comment}
            placeholder="Add comment..."
            onChange={(e) => setComment(e.target.value)}
            // onKeyDown={handleKeyDown}
          />
          <button
            className="right floated ui button primary"
            type="button"
            style={{ maxWidth: "100px", border: "none" }}
            onClick={handleSubmit}
            disabled={!submitButtonToggle} // Use the state directly here
          >
            Post
          </button>
        </div>
      </form>
    </div>
  );
}
