import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../App.jsx"

export function AddExperienceButton({ feedData, setFeedData }){
    const { isAuthenticated } = useContext(AuthContext);

    let navigate = useNavigate()

    const handleClick = () => {
        if (!isAuthenticated) {
            alert("You are not logged in. Please login and try again.")
        }
        else{
            navigate("/explore")
        }
    }

    return(
        <button 
            className="large ui primary button" 
            style={{ 
                backgroundColor: '#2b2d42', 
                color: 'white', 
                fontWeight: 'bold', 
                padding: '10px 20px', 
                borderRadius: '5px', 
                border: 'none', 
                display: 'block', 
                margin: '0 auto',
                marginBottom: '25px',
            }} 
            onClick={
                () => handleClick()
            }>
            Add Experience
        </button>
    )
}