import React, {useState, useContext} from 'react';
import "../../css/form.css"
import { register } from '../../api/Auth';
import TokenService from '../../utils/Token';
import { AuthContext } from '../../App.jsx';
import { getCurrentUser } from '../../api/Users';

export default function RegisterForm(){
    const [full_name, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [verify_password, setVerifyPassword] = useState("")
    const [failure, setFailure] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {loginContext} = useContext(AuthContext);

    function handleFailureClose(){
        setFailure(false);
    }

    // function handleLogin(event) {
    //     event.preventDefault();
    //     console.log(email, password)
    //     if (email === "" || password === "") {
    //         setErrorMessage("Email or password is empty")
    //         setFailure(true);
    //     }
    //     else{
    //       login({email, password}).then((response) => {
    //         if (response.status === 200) {
    //             setSuccess(true);
    //             TokenService.setToken(response.data)
    //             getCurrentUser().then(data => {
    //                 loginContext(data)
    //             });
    //         }
    //         else {
    //             setErrorMessage("Email or password is incorrect")
    //             setFailure(true);
    //         }
    //       })
    //     }
        
    // }

    function handleRegister(e){
        e.preventDefault();
        if (password !== "" || verify_password !== "" ){
            if(password === verify_password){
                // post to create user endpoint
                // this will return an access token and a refresh token
                register({full_name, email, username, password, verify_password}).then((response) => {
                    if(response.status === 200){
                        TokenService.setToken(response.data)
                        getCurrentUser().then(data => { 
                            loginContext(data)
                        }).catch((err) => {
                            console.log(err)
                        })
                    } 
                    else {
                        setErrorMessage("Passwords do not match")
                        setFailure(true);
                    }
                })
            }
        } else {
            setErrorMessage("Password cannot be empty")
            setFailure(true);
        }
    }

    return(
        <form className="form" onSubmit={handleRegister}>
            <h2>Register</h2>
            <div className="label">Full Name</div>
            <input className="input" type="text" name="full_name" value={full_name} onChange={e => setFullName(e.target.value)} placeholder="Jeets Eats"></input>

            <div className="label">Email</div>
            <input className="input" type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="email@email.com"></input>

            <div className="label">Username</div>
            <input className="input" type="text" name="username" value={username} onChange={e => setUsername(e.target.value)} placeholder="username"></input>

            <div className="label">Password</div>
            <input className="input" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="password"></input>

            <div className="label">Verify Password</div>
            <input className="input" type="password" name="verify_password" value={verify_password} onChange={e => setVerifyPassword(e.target.value)} placeholder="verify password"></input>
            
            <button className="button" type="submit">
                Register
            </button>
            {failure && <div className="ui error message"><i class="close icon" onClick={() => handleFailureClose()}></i> {errorMessage} </div>} 
        </form>
    )
}