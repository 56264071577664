import { requestService } from '../utils/Utils.jsx';

export function getExperiences() {
    return requestService.get('/experiences');
}

export function getUserExperiences(userId, token) {
    if(token){
        requestService.headers.Authorization = `Bearer ${token}`;
    }
    let response = requestService.get(`/experience/user/${userId}`).then((data) => {
        console.log(data)
        console.log("data from get user experiences", data)
        return data
    }).catch((err) => {
        console.log(err)
    })
    return response
}

export function getUserSavedExperiences(userId, token){
    if(token){
        requestService.headers.Authorization = `Bearer ${token}`;
    }
    let response = requestService.get(`/experience/user/${userId}/saved`).then((data) => {
        console.log(data)
        console.log("data from get user experiences", data)
        return data
    }).catch((err) => {
        console.log(err)
    })
    return response
}

export function getExperienceById(id) {
    let response = requestService.get(`/experiences/${id}`).then(response => {
        console.log(response)
        return response.data
    }).catch((err) => {
        console.log(err)
    })
    return response.data
}

export async function createExperience(experience) {
    console.log("experience in createExperience", experience)
    console.log("typeof experience data", typeof(experience.data))
    try{
        let response = await requestService.post('/experiences/create', experience).then((response) => {
            console.log("response", response)
            return response
        }).catch((err) => {
            console.log("err", err)
            return "error"
        })
        return response
    }
    catch(err){
        console.log(err)
    }
}

export function updateExperience(experience) {
    return requestService.put(`/experiences/${experience.id}`, experience);
}

export function deleteExperience(id) {
    return requestService.delete(`/experiences/${id}`);
}

export function getAllExperiences() {
    return requestService.get('/experiences').then((response) => {
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}