import TokenService from './Token';
import axios from 'axios';

export const requestService = axios.create({
    baseURL: "https://rdqpremhzz.us-west-2.awsapprunner.com",
    // baseURL: "http://127.0.0.1:8000"
});
  

export function checkUserLogin(){
    if (TokenService.getAccessToken() && TokenService.getRefreshToken()) {
        if (!TokenService.getAccessTokenValidity() && TokenService.getRefreshTokenValidity()) {
            // get a new access token with the existing valid refresh token
            requestService.post(`/auth/refresh?refresh_token=${TokenService.getRefreshToken()}`
            ).then(response => {
                TokenService.setToken(response.data)
                return true
            }).catch(err => {
                console.log(err)
            })
        }
        if (!TokenService.getAccessTokenValidity() && !TokenService.getRefreshTokenValidity()) {
            // redirect user to login page
            TokenService.clearToken();
            return false
        }
        if (TokenService.getAccessTokenValidity() && TokenService.getRefreshTokenValidity()) {
            // access token is valid
            return true
        }
    }
    return false;
}

requestService.interceptors.request.use(
    (config) => {
      const token = TokenService.getAccessToken();
    //   console.log("This is the acces token from the interceptor " + token)
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

requestService.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        const valid = TokenService.getRefreshTokenValidity();
        // if refresh token is expired, redirect user to login with action
        if (!valid) {
            TokenService.clearToken();
        }

        if (error.response.status === 401 && !originalRequest.retry) {
            originalRequest.retry = true;
            return requestService({
                url: '/auth/refresh',
                method: 'post',
                data: {
                    refresh: TokenService.getRefreshToken(),
                },
            }).then((res) => {
                if (res.status === 200) {
                    TokenService.setToken(res.data);

                    requestService.defaults.headers.common.Authorization = `Bearer ${TokenService.getAccessToken()}`;

                    return requestService(originalRequest);
                }
                return null;
            });
        }
        return Promise.reject(error);
    }
);