import React, { useState, useEffect, useContext } from "react";
import { submitFeedback, getUserFeedbackEligibility } from "../api/Feedback"
import { AuthContext } from "../App.jsx"


export default function ToBeImplemented({title, description}) {
    const [opted, setOpted] = useState("")
    const [eligibility, setEligibility] = useState(false)
    const { currentUser, isAuthenticated } = useContext(AuthContext);


    useEffect(() => {
        if(isAuthenticated){
            const fetchData = async () => {
                const returnValue = await getUserFeedbackEligibility(currentUser.id, description);
                setEligibility(returnValue)
            };
            fetchData()
        }
    }, [isAuthenticated, currentUser, description])

    function handleFormSubmit(event){
        event.preventDefault();
        console.log("submitting feedback")
        if (eligibility === true) {
            const formData = new FormData();
            formData.append("feature", description);
            formData.append("opted", opted);
            formData.append("user_id", currentUser.id);
            submitFeedback(formData).then(data => {
                console.log(data)
                setEligibility(true)
            })
        }
    }

    return(
        <div className="ui container" style={{ maxWidth: "500px", margin: "0 auto", backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "5px" }}>
            {eligibility ? (
                <>
                <h3>This feature is not available yet!</h3>
                <form onSubmit={handleFormSubmit} className="ui form" style={{ maxWidth: "500px", margin: "0 auto", backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "5px" }}>
                <div className="field">
                    <h4>{title}</h4>
                    <div className="field">
                        <label>
                            Yes
                        </label>
                        <input
                        type="radio"
                        name="opted"
                        value="yes"
                        checked={opted === "yes"}
                        onChange={() => setOpted("yes")}
                        />
                    </div>
                    <div className="field">
                        <label>
                            No
                        </label>
                        <input
                            type="radio"
                            name="mapFeature"
                            value="no"
                            checked={opted === "no"}
                            onChange={() => setOpted("no")}
                            />
                    </div>
                </div>
                <button type="submit" className="ui blue button" style={{ marginTop: "20px" }}> Submit</button>
            </form>
            </>) : (
                <>
                    <h3>Thanks for your submission!</h3>
                    <p>Thank you for submitting your response today. You can submit another one tomorrow.</p>
                </>
                )
            }
        </div>
    )
}