import React, {useState, useContext } from 'react'
import { AuthContext } from "../../App.jsx"

import { Link } from 'react-router-dom';


export default function CommentCard({data, deleteItem}) {
    const { currentUser, isAuthenticated } = useContext(AuthContext)
    const [toggleCommentOptions, setToggleCommentOptions] = useState(false)
    let commentData = data

    const showCommentOptionsMenu = () => {
        // console.log("show comment options menu", data, currentUser.username)
        if(isAuthenticated && currentUser.username === data.username){
            console.log("toggle comment options")
            setToggleCommentOptions(!toggleCommentOptions)
        }
    }

    const CommentOptionsMenuButton = () => {
        const handleDeleteComment = () => {
            if(isAuthenticated && currentUser.username === commentData.username){
                console.log("delete comment", commentData.id)
                deleteItem(commentData.id)
                // deleteComment(commentData.id)
            }
            else if(isAuthenticated && currentUser.username !== commentData.username){
                console.log("authenticated, but not the same user")
            }
            else{
                console.log("not authenticated")
            }
        }

        return (
            <div className="ui left pointing red basic label" onClick={handleDeleteComment} style={{alignContent: "center", textAlign: "center"}}>
                delete
            </div>
        )
    }
    
    return (
        <div className="item" style={{ display: "flex", width: "100%", paddingTop: "10px"}}>
            <img className="ui avatar image" src={commentData.profile_photo} alt="profile"></img>
            <div className="middle aligned" style={{paddingLeft: "5px"}}>
                <Link to={`/${commentData.username}`} className="header">{commentData.username}</Link>
                {/* <a className="header">{commentData.username}</a> */}
                <div className="description" style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>{commentData.text}</div>
            </div>
            {/* <button className="ui small button" onClick={outputCommentId}>Output Comment Id</button> */}
            <div className="right floated content">
                <i className="ui icon ellipsis horizontal" onClick={showCommentOptionsMenu}> </i>
            </div>
            { toggleCommentOptions ? <CommentOptionsMenuButton/> : null }
        </div>
    )
}
